import "../styles/addPatientStyle.css";

function AddPatientCompo(props) {
  return (
    <div className="addPatientPageParentContainer">
      <div className="addPatientForm">
        <div className="addPatientPageHeading">
          <span className="componentHeading">Add a New Patient</span>
        </div>

        <div className="formFields">
          <label htmlFor="hospitalId">Hospital ID
          <span className="errorMsg">Id is a must! </span>
          </label>
          <input
            id="hospitalId"
            type="text"
            onChange={props.listenhospitalid}
            value={props.hospitalid}
          ></input>
          {/* <p className="icon"></p> */}
        </div>

        <div className="formFields">
          <label htmlFor="patFirstName">First Name
          <span className="errorMsg">Fname is a must!</span>
          <span className="errorMsg">Only Alphabets!</span>
          </label>
          <input
            id="patFirstName"
            className="errorplaceholder"
            type="text"
            onChange={props.listenfirstname}
            value={props.firstname}
          ></input>
        </div>
        <div className="formFields">
          <label htmlFor="patLastName">Last Name
          <span className="errorMsg">Only Alphabets!</span>
          </label>
          <input
            id="patLastName"
            type="text"
            onChange={props.listenlastname}
            value={props.lastname}
          ></input>
        </div>
        <div className="formFields">
          <label htmlFor="patPhoneNumber">Phone Number
          <span className="errorMsg"> Number is a must! </span>
          <span className="errorMsg"> Invalid Number! </span>
          </label>
          <input
            id="patPhoneNumber"
            type="text"
            onChange={props.listenphonenumber}
            value={props.phonenumber}
          ></input>
        </div>
        <div className="formFields">
          <label htmlFor="patEmail">Email ID
          <span className="errorMsg" >Invalid Email!</span>
          </label>
          <input
            id="patEmail"
            type="text"
            onChange={props.listenemailid}
            value={props.emailid}
          ></input>
        </div>
        <div className="formFields">
          <label htmlFor="patAge">Age
          <span className="errorMsg">Age is a must! </span>
          <span className="errorMsg">Only Numbers!</span>
          </label>
          <input
            id="patAge"
            type="text"
            onChange={props.listenage}
            value={props.age}
          ></input>
        </div>
        <div className="formFields">
          <label htmlFor="patHeight">Height (meters)
          <span className="errorMsg">Height is a must! </span>
          <span className="errorMsg">Only Numbers!</span>
          </label>
          <input
            id="patHeight"
            type="text"
            onChange={props.listenheight}
            value={props.height}
          ></input>
        </div>
        <div className="formControls">
          <button onClick={props.sendpatientdata} className="Btn" id="saveBtn">
            Save
          </button>
          <button onClick={props.cleardata} className="Btn" id="clearBtn">
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}
export default AddPatientCompo;
