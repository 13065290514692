import axios from "axios";
import { useEffect } from "react";
import Header from "../components/header";
import AddPatientCompo from "../components/addPatientCompo";
import PatientDashboardCompo from "../components/PatientDashboardCompo";
import "../styles/adminStyle.css";
import logoBackground from "../assets/logo_Background_SahayaDiab.svg";
import { useState } from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function Admin() {
  const [patientList, setPatientList] = useState([]);
  const [hospitalId, setHospitalId] = useState("");
  const [errorPlaceHolderId, setErrorPlaceHolderId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [errorPlaceHolderFname, setErrorPlaceHolderFname] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorPlaceHolderLname, setErrorPlaceHolderLname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorPlaceHolderPhone, setErrorPlaceHolderPhone] = useState("");
  const [emailId, setEmailId] = useState("");
  const [errorPlaceHolderEmail, setErrorPlaceHolderEmail] = useState("");
  const [age, setAge] = useState("");
  const [errorPlaceHolderAge, setErrorPlaceHolderAge] = useState("");
  const [height, setHeight] = useState("");
  const [errorPlaceHolderHeight, setErrorPlaceHolderHeight] = useState("");

  const [errorFlag, setErrorFlag] = useState(false);

  const [selectedPatient, setSelectedPatient] = useState("");

  const [flagPatientDashboard, setFlagPatientDashboard] = useState("false");
  const [FlagAddPatient, setFlagAddPatient] = useState("true");
  const [flagPatientcontainer, setflagPatientcontainer] = useState("false");

  const [bmiDateArray, setBmiDateArray] = useState([]);
  const [bmiValueArray, setBmiValueArray] = useState([]);

  const [glucoseDateArray, setGlucoseDateArray] = useState([]);
  const [glucoseValueArray, setGlucoseValueArray] = useState([]);

  const [exerciseDateArray, setExerciseDateArray] = useState([]);
  const [exerciseIntensityArray, setExerciseIntensityArray] = useState([]);
  const [exerciseDurationArray, setExerciseDurationArray] = useState([]);
  const [exerciseSleepArray, setExerciseSleepArray] = useState([]);

  const [confidenceDateArray, setConfidenceDateArray] = useState([]);
  const [confidenceLevelArray, setConfidenceLevelArray] = useState([]);

  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const [detailsHospitalId, setDetailsHospitalId] = useState("");
  const [detailsFirstName, setDetailsFirstName] = useState("");
  const [detailsLastName, setDetailsLastName] = useState("");
  const [detailsPhoneNumber, setDetailsPhoneNumber] = useState("");
  const [detailsEmailId, setDetailsEmailId] = useState("");
  const [detailsAge, setDetailsAge] = useState("");
  const [detailsHeight, setDetailsHeight] = useState("");

  const [newFirstName, setNewFirstName] = useState(detailsFirstName);
  const [newLastName, setNewLastName] = useState(detailsLastName);
  const [newPhoneNumber, setNewPhoneNumber] = useState(detailsPhoneNumber);
  const [newEmailId, setNewEmailId] = useState(detailsEmailId);
  const [newAge, setNewAge] = useState(detailsAge);
  const [newHeight, setNewHeight] = useState(detailsHeight);

  const [exportPatData, setExportPatData] = useState();
  const [exportBmiData, setExportBmiData] = useState();
  const [exportGlucouseData, setExportGlucouseData] = useState();
  const [exportExerciseData, setExportExerciseData] = useState();
  const [exportConfidenceData, setExportConfidenceData] = useState();

  //get patient list from backend
  useEffect(() => {
    axios
      .get(
        /*"/getpatient"*/ "https://asia-south1-sahayadiab.cloudfunctions.net/app/getpatient"
      )
      .then(function (res) {
        //console.log(res.data);
        setPatientList(res.data);
      });
  }, []);

  //Listen function
  function listenHospitalId(event) {
    var value = event.target.value;
    setErrorFlag(false);
    if (value.length === 0) {
      document.getElementById("hospitalId").className = "errorField";
      document.getElementsByClassName("errorMsg")[0].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      document.getElementById("hospitalId").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[0].style.display = "none";
      setErrorFlag(false);
    }
    setHospitalId(event.target.value);
  }

  //Listen function
  function listenFirstName(event) {
    var value = event.target.value;
    const regex = /^[a-zA-Z]+$/;
    if (value.length === 0) {
      document.getElementById("patFirstName").className = "errorField";
      document.getElementsByClassName("errorMsg")[1].style.display =
        "inline-block";
      document.getElementsByClassName("errorMsg")[2].style.display = "none";
      setErrorFlag(true);
    } else if (!regex.test(value)) {
      document.getElementById("patFirstName").className = "errorField";
      document.getElementsByClassName("errorMsg")[1].style.display = "none";
      document.getElementsByClassName("errorMsg")[2].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      document.getElementById("patFirstName").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[1].style.display = "none";
      // document.getElementsByClassName("errorMsg")[2].style.display="none"
      setErrorFlag(false);
    }
    //console.log("current live first name: ", firstName);
    setFirstName(event.target.value);
  }

  //Listen function
  function listenLastName(event) {
    var value = event.target.value;
    const regex = /^[a-zA-Z]+$/;
    if (value.length > 0 && !regex.test(value)) {
      document.getElementById("patLastName").className = "errorField";
      document.getElementsByClassName("errorMsg")[3].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      document.getElementById("patLastName").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[3].style.display = "none";
      setErrorFlag(false);
    }
    setLastName(event.target.value);
  }

  //Listen function
  function listenPhoneNumber(event) {
    var value = event.target.value;
    const regex = /\d/;
    if (value.length === 0) {
      document.getElementById("patPhoneNumber").className = "errorField";
      document.getElementsByClassName("errorMsg")[4].style.display =
        "inline-block";
      document.getElementsByClassName("errorMsg")[5].style.display = "none";
      setErrorFlag(true);
    } else if (value.length !== 10 || !regex.test(value)) {
      document.getElementById("patPhoneNumber").className = "errorField";
      document.getElementsByClassName("errorMsg")[4].style.display = "none";
      document.getElementsByClassName("errorMsg")[5].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      document.getElementById("patPhoneNumber").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[5].style.display = "none";
      setErrorFlag(false);
    }
    setPhoneNumber(event.target.value);
  }

  //Listen function
  function listenEmailId(event) {
    var value = event.target.value;
    const regex =
      /^[A-Z0-9._%+-]+@(gmail|yahoo|outlook|hotmail|icloud)+\.(com|in|edu|org|gov|us)+$/i;
    if (value.length > 0 && !regex.test(value)) {
      document.getElementById("patEmail").className = "errorField";
      document.getElementsByClassName("errorMsg")[6].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      document.getElementById("patEmail").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[6].style.display = "none";
      setErrorFlag(false);
    }
    setEmailId(event.target.value);
  }

  //Listen function
  function listenAge(event) {
    var value = event.target.value;
    const regex = /\d/;
    if (value.length === 0) {
      document.getElementById("patAge").className = "errorField";
      document.getElementsByClassName("errorMsg")[7].style.display =
        "inline-block";
      document.getElementsByClassName("errorMsg")[8].style.display = "none";
      setErrorFlag(true);
    } else if (!regex.test(value)) {
      document.getElementById("patAge").className = "errorField";
      document.getElementsByClassName("errorMsg")[7].style.display = "none";
      document.getElementsByClassName("errorMsg")[8].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      //console.log("Age is not empty");
      document.getElementById("patAge").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[7].style.display = "none";
      document.getElementsByClassName("errorMsg")[8].style.display = "none";
      setErrorFlag(false);
    }
    setAge(event.target.value);
  }

  //Listen function
  function listenHeight(event) {
    var value = event.target.value;
    const regex = /\d/;
    if (value.length === 0) {
      document.getElementById("patHeight").className = "errorField";
      document.getElementsByClassName("errorMsg")[9].style.display =
        "inline-block";
      document.getElementsByClassName("errorMsg")[10].style.display = "none";
      setErrorFlag(true);
    } else if (!regex.test(value)) {
      document.getElementById("patHeight").className = "errorField";
      document.getElementsByClassName("errorMsg")[9].style.display = "none";
      document.getElementsByClassName("errorMsg")[10].style.display =
        "inline-block";
      setErrorFlag(true);
    } else {
      document.getElementById("patHeight").classList.remove("errorField");
      document.getElementsByClassName("errorMsg")[9].style.display = "none";
      document.getElementsByClassName("errorMsg")[10].style.display = "none";
      setErrorFlag(false);
    }
    setHeight(event.target.value);
  }

  function openAddPatient() {
    setFlagPatientDashboard("false");
    setFlagAddPatient("true");
  }

  // send patient data to backend
  function sendPatientData(event) {
    event.preventDefault();
    if (errorFlag === true) {
      //console.log("cant submit!");
      return;
    }
    const config = {
      method: "POST",
      url: /*"/addpatient"*/ "https://asia-south1-sahayadiab.cloudfunctions.net/app/addpatient",
      data: {
        hospitalId,
        firstName,
        lastName,
        phoneNumber,
        emailId,
        age,
        height,
      },
    };
    axios(config)
      .then(() => {
        setHospitalId("");
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setEmailId("");
        setAge("");
        setHeight("");
        setErrorPlaceHolderId("");
      })
      .then(() => {
        setPatientList([...patientList, hospitalId]);
      });
  }

  function clearData(event) {
    event.preventDefault();
    setHospitalId("");
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setEmailId("");
    setAge("");
    setHeight("");
  }

  //listen for the selected patient
  function listenPatient(event) {
    //console.log("selected patient====== ", event.target.value);
    setSelectedPatient(event.target.value);
    setFlagAddPatient("false");
    setFlagPatientDashboard("true");

    //let urls = ["/getpatientdetails", "/getgraphbmi", "getgraphglucose"];
    let urls = [
      "https://asia-south1-sahayadiab.cloudfunctions.net/app/getpatientdetails",
      "https://asia-south1-sahayadiab.cloudfunctions.net/app/getgraphbmi",
      "https://asia-south1-sahayadiab.cloudfunctions.net/app/getgraphglucose",
    ];
    const requests = urls.map((url) =>
      axios.get(url, { params: { selectedPatient: event.target.value } })
    );

    axios.all(requests).then(function (responses) {
      //setting the values to be displayed of the selected patient
      setDetailsHospitalId(responses[0].data._id);
      setDetailsFirstName(responses[0].data.PatientFirstName);
      setDetailsLastName(responses[0].data.PatientLastName);
      setDetailsPhoneNumber(responses[0].data.PatientPhoneNumber);
      setDetailsEmailId(responses[0].data.PatientEmail);
      setDetailsAge(responses[0].data.PatientAge);
      setDetailsHeight(responses[0].data.PatientHeight);

      setNewFirstName(responses[0].data.PatientFirstName);
      setNewLastName(responses[0].data.PatientLastName);
      setNewPhoneNumber(responses[0].data.PatientPhoneNumber);
      setNewEmailId(responses[0].data.PatientEmail);
      setNewAge(responses[0].data.PatientAge);
      setNewHeight(responses[0].data.PatientHeight);

      //making a json data to export
      setExportPatData(responses[0].data);
      setExportBmiData(responses[1].data.PatientBmi);
      setExportGlucouseData(responses[2].data[0].PatientBloodGlucose);
      setExportExerciseData(responses[1].data.PatientExercise);
      setExportConfidenceData(responses[1].data.PatientConfidence);

      //console.log("====== ", responses[2].data[0].PatientBloodGlucose);

      /* BMI Section */

      var PatientBmiArray = responses[1].data.PatientBmi;

      // setexportPatientBmiData(responses[1].data.PatientBmi);
      // let exportjsondata = JSON.stringify(responses[0].data)
      var bmiDateTempArray = [];
      var bmiValueTempArray = [];

      if (responses[1].data.PatientBmi.length === 0) {
        setBmiDateArray(["0"]);
        setBmiValueArray(["0"]);
      } else {
        // Storing BMI Dates in temp array:
        PatientBmiArray.forEach((element) => {
          var date = element.BmiDate.substring(0, 10);
          bmiDateTempArray.push(date);
        });
        // Storing BMI Values in temp array:
        PatientBmiArray.forEach((element) => {
          bmiValueTempArray.push(element.BmiValue);
        });

        //Setting the temp array to bmi date array state
        setBmiDateArray(bmiDateTempArray);
        //Setting the temp array to bmi value array state
        setBmiValueArray(bmiValueTempArray);
      }

      /* Glucose Section */
      var PatientGlucoseArray = responses[2].data[0].PatientBloodGlucose;
      var glucoseDateTempArray = [];
      var glucoseValueTempArray = [];

      if (responses[2].data[0].PatientBloodGlucose.length === 0) {
        setGlucoseDateArray(["0"]);
        setGlucoseValueArray(["0"]);
      } else {
        // Creating a temp array for Glucose date:
        PatientGlucoseArray.forEach((e) => {
          var date = e.GlucoseDate.substring(0, 10);
          glucoseDateTempArray.push(date);
        });

        // Creating a temp array for Glucose value: while calculating mean of all values per day
        PatientGlucoseArray.forEach((e) => {
          //console.log(e);
          //console.log("glucosy individual data: ", e);
          var avg =
            (parseInt(e.GlucoseValueAfternoonAfterLunch) +
              parseInt(e.GlucoseValueMorningAfterBreakfast) +
              parseInt(e.GlucoseValueMorningBeforeBreakfast) +
              parseInt(e.GlucoseValueNightAfterDinner)) /
            6;

          //console.log("glucosy avg data: ", avg);
          glucoseValueTempArray.push(avg);
        });

        setGlucoseDateArray(glucoseDateTempArray);
        setGlucoseValueArray(glucoseValueTempArray);
      }

      /* Exercise Section */
      var PatientExerciseArray = responses[1].data.PatientExercise;
      //console.log("exercise data: ", responses[1].data.PatientExercise);
      // setexportPatientBmiData(responses[1].data.PatientBmi);
      // let exportjsondata = JSON.stringify(responses[0].data)

      var exerciseDateTempArray = [];
      var exerciseIntensityTempArray = [];
      var exerciseDurationTempArray = [];
      var exerciseSleepTempArray = [];

      if (responses[1].data.PatientExercise.length === 0) {
        setExerciseDateArray(["0"]);
        setExerciseIntensityArray(["0"]);
        setExerciseDurationArray(["0"]);
        setExerciseSleepArray(["0"]);
      } else {
        // Storing Exercise Dates in temp array:
        PatientExerciseArray.forEach((element) => {
          var date = element.ExerciseDate.substring(0, 10);
          exerciseDateTempArray.push(date);
        });

        // Storing Exercise intensity in temp array:
        PatientExerciseArray.forEach((element) => {
          //console.log("intensity data: ", element.ExerciseIntensity);
          if (element.ExerciseIntensity === "<30 min") {
            exerciseIntensityTempArray.push(1);
          } else if (element.ExerciseIntensity === "30 min") {
            exerciseIntensityTempArray.push(2);
          } else {
            exerciseIntensityTempArray.push(3);
          }
        });
        // Storing Exercise duration in temp array:
        PatientExerciseArray.forEach((element) => {
          //console.log("duration data: ", element.ExerciseDuration);
          if (element.ExerciseDuration === "Mild") {
            exerciseDurationTempArray.push(1);
          } else if (element.ExerciseDuration === "Moderate") {
            exerciseDurationTempArray.push(2);
          } else {
            exerciseDurationTempArray.push(3);
          }
        });
        // Storing Exercise sleep in temp array:
        PatientExerciseArray.forEach((element) => {
          //console.log("sleep data: ", element.ExerciseSleep);
          if (element.ExerciseSleep === "<7 hours") {
            exerciseSleepTempArray.push(1);
          } else if (element.ExerciseSleep === "7 hours") {
            exerciseSleepTempArray.push(2);
          } else {
            exerciseSleepTempArray.push(3);
          }
        });

        //console.log("intensityyyyy: ", exerciseIntensityTempArray);
        //console.log("durationnnnn: ", exerciseDurationTempArray);
        //console.log("sleeepppppp: ", exerciseSleepTempArray);

        //Setting the temp array to bmi date array state
        setExerciseDateArray(exerciseDateTempArray);
        //Setting the temp array to bmi date array state
        setExerciseIntensityArray(exerciseIntensityTempArray);
        //Setting the temp array to bmi date array state
        setExerciseDurationArray(exerciseDurationTempArray);
        //Setting the temp array to bmi date array state
        setExerciseSleepArray(exerciseSleepTempArray);
        //console.log(exerciseDateTempArray);
      }

      /* Confidence Section */
      var PatientConfidenceArray = responses[1].data.PatientConfidence;
      console.log("confidence data: ", responses[1].data.PatientConfidence);
      // setexportPatientBmiData(responses[1].data.PatientBmi);
      // let exportjsondata = JSON.stringify(responses[0].data)

      var confidenceDateTempArray = [];
      var confidencelevelTempArray = [];

      if (responses[1].data.PatientConfidence.length === 0) {
        console.log("confidence is empty");
        setConfidenceDateArray(["0"]);
        setConfidenceLevelArray(["0"]);
      } else {
        // Storing Exercise Dates in temp array:
        PatientConfidenceArray.forEach((element) => {
          var date = element.ConfidenceDate.substring(0, 10);
          confidenceDateTempArray.push(date);
        });

        // Storing Exercise intensity in temp array:
        PatientConfidenceArray.forEach((element) => {
          if (element.ConfidenceLevel === "Low") {
            confidencelevelTempArray.push(1);
          } else if (element.ConfidenceLevel === "Moderate") {
            confidencelevelTempArray.push(2);
          } else if (element.ConfidenceLevel === "High") {
            confidencelevelTempArray.push(3);
          } else if (element.ConfidenceLevel === "Very High") {
            confidencelevelTempArray.push(4);
          }
        });
        setConfidenceDateArray(confidenceDateTempArray);
        setConfidenceLevelArray(confidencelevelTempArray);
      }
    });
  }

  function editpatientdata(event) {
    event.preventDefault();

    //setFirstName(firstName);

    /*
    setLastName(detailsLastName);
    setPhoneNumber(detailsPhoneNumber);
    setEmailId(detailsEmailId);
    setAge(detailsAge);
    setHeight(detailsHeight);
    */

    //console.log("first name after edit: ", newFirstName);
    //console.log("first name before edit: ", newFirstName);

    const config = {
      method: "POST",
      url: /*"/editpatient"*/ "https://asia-south1-sahayadiab.cloudfunctions.net/app/editpatient",
      data: {
        selectedPatient,
        newFirstName,
        newLastName,
        newPhoneNumber,
        newEmailId,
        newAge,
        newHeight,
      },
    };
    axios(config).then((res) => {
      setDeleteConfirmation(res.data);
      window.location.reload(true);
    });
  }

  function deletePatient(event) {
    event.preventDefault();

    const config = {
      method: "POST",
      url: /*"/delpatient"*/ "https://asia-south1-sahayadiab.cloudfunctions.net/app/delpatient",
      data: {
        selectedPatient,
      },
    };
    axios(config).then((res) => {
      setDeleteConfirmation(res.data);
      window.location.reload(true);
    });
  }

  function listenNewFirstName(event) {
    setNewFirstName(event.target.value);
    //console.log("new first name: ", newFirstName);
  }

  function listenNewLastName(event) {
    setNewLastName(event.target.value);
  }

  function listenNewPhoneNumber(event) {
    setNewPhoneNumber(event.target.value);
  }

  function listenNewEmailId(event) {
    setNewEmailId(event.target.value);
  }

  function listenNewAge(event) {
    setNewAge(event.target.value);
  }
  function listenNewHeight(event) {
    setNewHeight(event.target.value);
  }

  return (
    <div className="adminPageParentContainer">
      <Header />
      <div className="dashboardContainer">
        <div className="leftPanelContainer">
          <div className="addPatientBtnContainer">
            <button className="addPatientBtn" onClick={openAddPatient}>
              Add Patient +
            </button>
          </div>
          <div className="patientListContainer">
            <span className="patientListHeading">Patient List</span>
            {patientList.map((patients, patientListKey) => (
              <div key={patientListKey} className="patientContainer">
                <button value={patients} onClick={listenPatient}>
                  {patients}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="rightPanelContainer">
          {flagPatientDashboard === "true" ? (
            <PatientDashboardCompo
              selectedPatient={selectedPatient}
              deletePatient={deletePatient}
              listennewfirstname={listenNewFirstName}
              listennewlastname={listenNewLastName}
              listennewphonenumber={listenNewPhoneNumber}
              listennewemailid={listenNewEmailId}
              listennewage={listenNewAge}
              listennewheight={listenNewHeight}
              editpatientdata={editpatientdata}
              bmiDateArray={bmiDateArray}
              bmiValueArray={bmiValueArray}
              glucoseDateArray={glucoseDateArray}
              glucoseValueArray={glucoseValueArray}
              exerciseDateArray={exerciseDateArray}
              exerciseIntensityArray={exerciseIntensityArray}
              exerciseDurationArray={exerciseDurationArray}
              exerciseSleepArray={exerciseSleepArray}
              confidenceDateArray={confidenceDateArray}
              confidenceLevelArray={confidenceLevelArray}
              detailshospitalId={detailsHospitalId}
              detailsfirstname={detailsFirstName}
              detailslastname={detailsLastName}
              detailsphonenumber={detailsPhoneNumber}
              detailsemailid={detailsEmailId}
              detailsage={detailsAge}
              detailsheight={detailsHeight}
              newfirstname={newFirstName}
              newlastname={newLastName}
              newphonenumber={newPhoneNumber}
              newemailid={newEmailId}
              newage={newAge}
              newheight={newHeight}
              patdata={exportPatData}
              bmidata={exportBmiData}
              glucousedata={exportGlucouseData}
              exercisedata={exportExerciseData}
              confidencedata={exportConfidenceData}
            />
          ) : (
            <AddPatientCompo
              listenhospitalid={listenHospitalId}
              listenfirstname={listenFirstName}
              listenlastname={listenLastName}
              listenphonenumber={listenPhoneNumber}
              listenemailid={listenEmailId}
              listenage={listenAge}
              listenheight={listenHeight}
              sendpatientdata={sendPatientData}
              cleardata={clearData}
              hospitalid={hospitalId}
              firstname={firstName}
              lastname={lastName}
              phonenumber={phoneNumber}
              emailid={emailId}
              age={age}
              height={height}
            />
          )}
          <div className="logoBackground">
            <img
              className="logoBackgroundImage"
              src={logoBackground}
              alt="Logo Background"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Admin;
