import { useEffect, useMemo, useState } from "react";
import Plotly from "plotly.js-dist";
import * as d3 from "d3";

function GraphsCompo(props) {
  //const [bmiDateArray, setbmiDateArray] = useState([]);
  //const [bmiValueArray, setbmiValueArray] = useState([]);

  //const bmiDateArray = useMemo(() => [], []);
  //const bmiValueArray = useMemo(() => [], []);

  const [target, setTarget] = useState(document.getElementById("trackerBody"));
  const [target2, setTarget2] = useState(
    document.getElementById("trackerBody2")
  );
  const [target3, setTarget3] = useState(
    document.getElementById("trackerBody3")
  );
  const [target4, setTarget4] = useState(
    document.getElementById("trackerBody4")
  );
  const [target5, setTarget5] = useState(
    document.getElementById("trackerBody5")
  );
  const [target6, setTarget6] = useState(
    document.getElementById("trackerBody6")
  );

  var bmiDateArray = props.bmiDateArray;
  var bmiValueArray = props.bmiValueArray;

  var glucoseDateArray = props.glucoseDateArray;
  var glucoseValueArray = props.glucoseValueArray;

  var exerciseDateArray = props.exerciseDateArray;
  var exerciseIntensityArray = props.exerciseIntensityArray;
  var exerciseDurationArray = props.exerciseDurationArray;
  var exerciseSleepArray = props.exerciseSleepArray;

  var confidenceDateArray = props.confidenceDateArray;
  var confidenceLevelArray = props.confidenceLevelArray;

  var bmiDateArrayMin, bmiDateArrayMax, bmiValueArrayMin, bmiValueArrayMax;
  var glucoseDateArrayMin,
    glucoseDateArrayMax,
    glucoseValueArrayMin,
    glucoseValueArrayMax;

  var exerciseDateArrayMin,
    exerciseDateArrayMax,
    exerciseIntensityArrayMin,
    exerciseIntensityArrayMax,
    exerciseDurationArrayMin,
    exerciseDurationArrayMax,
    exerciseSleepArrayMin,
    exerciseSleepArrayMax;

  var confidenceDateArrayMin,
    confidenceDateArrayMax,
    confidenceLevelArrayMin,
    confidenceLevelArrayMax;

  useEffect(() => {
    setTarget(document.getElementById("trackerBody"));
    setTarget2(document.getElementById("trackerBody2"));
    setTarget3(document.getElementById("trackerBody4"));
    setTarget4(document.getElementById("trackerBody3"));
    setTarget5(document.getElementById("trackerBody5"));
    setTarget6(document.getElementById("trackerBody6"));
  }, []);

  bmiDateArrayMin = Math.min(...bmiDateArray);
  bmiDateArrayMax = Math.max(...bmiDateArray);

  bmiValueArrayMin = Math.min(...bmiValueArray);
  bmiValueArrayMax = Math.max(...bmiValueArray);

  if (
    (bmiDateArray.length !== 0 || bmiValueArray.length !== 0) &&
    target !== null
  ) {
    //var xArray = [50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150];
    var xArray = bmiDateArray;
    //var yArray = [7, 8, 8, 9, 9, 9, 10, 11, 14, 14, 15];
    var yArray = bmiValueArray;

    // Define Data
    var data = [
      {
        x: xArray,
        y: yArray,
        type: "scatter",
      },
    ];

    // Define Layout
    var layout = {
      xaxis: {
        range: [bmiDateArrayMin - 1, bmiDateArrayMax + 1],
        title: "Days",
      },
      yaxis: {
        range: [bmiValueArrayMin - 1, bmiValueArrayMax + 1],
        title: "BMI",
      },
      title: "BMI Graph",
      autoexpand: true,
    };

    var config = { responsive: true };

    var img_jpg = d3.select("#jpg-export");

    // Display using Plotly
    Plotly.newPlot(target, data, layout, config).then(function (gd) {
      //console.log("inside then.. ", gd);
      Plotly.toImage(gd, { format: "png", height: 400, width: 700 }).then(
        function (url) {
          //console.log("inside then..url.. ", url);
          img_jpg.attr("src", url);
        }
      );
    });
  }

  // BLOOD GLUCOSE GRAPH
  glucoseDateArrayMin = Math.min(...glucoseDateArray);
  glucoseDateArrayMax = Math.max(...glucoseDateArray);

  glucoseValueArrayMin = Math.min(...glucoseValueArray);
  glucoseValueArrayMax = Math.max(...glucoseValueArray);

  if (
    (glucoseDateArray.length !== 0 || glucoseValueArray.length !== 0) &&
    target2 !== null
  ) {
    // console.log("week array= ", bmiDateArray);
    //console.log("bmi value array= ", bmiValueArray);

    var xglucoseArray = glucoseDateArray;
    var yglucoseArray = glucoseValueArray;

    // Define Data
    var data2 = [
      {
        x: xglucoseArray,
        y: yglucoseArray,
        type: "scatter",
      },
    ];

    // Define Layout
    var layout2 = {
      xaxis: {
        range: [glucoseDateArrayMin - 1, glucoseDateArrayMax + 1],
        title: "Days",
      },
      yaxis: {
        range: [glucoseValueArrayMin - 1, glucoseValueArrayMax + 1],
        title: "GLUCOSE",
      },
      title: "Blood Sugar Graph",
      autoexpand: true,
    };

    var config2 = { responsive: true };

    // Display using Plotly
    Plotly.newPlot(target2, data2, layout2, config2);
  }

  //EXERSCIE INTESITY
  exerciseDateArrayMin = Math.min(...exerciseDateArray);
  exerciseDateArrayMax = Math.max(...exerciseDateArray);

  exerciseIntensityArrayMin = Math.min(...exerciseIntensityArray);
  exerciseIntensityArrayMax = Math.max(...exerciseIntensityArray);

  if (exerciseDateArray.length !== 0 && target2 !== null) {
    // console.log("week array= ", bmiDateArray);
    //console.log("bmi value array= ", bmiValueArray);

    var xexerciseArray = exerciseDateArray;
    var yexerciseArray = exerciseIntensityArray;

    // Define Data
    var data3 = [
      {
        x: xexerciseArray,
        y: yexerciseArray,
        type: "scatter",
      },
    ];

    // Define Layout
    var layout3 = {
      xaxis: {
        range: [exerciseDateArrayMin - 1, exerciseDateArrayMax + 1],
        title: "Days",
      },
      yaxis: {
        range: [exerciseIntensityArrayMin - 1, exerciseIntensityArrayMax + 1],
        title: "intensity",
      },
      title: "Exercise Intensity Graph",
      autoexpand: true,
    };

    var config3 = { responsive: true };

    // Display using Plotly
    Plotly.newPlot(target3, data3, layout3, config3);
  }

  //EXERCISE DURATION
  exerciseDateArrayMin = Math.min(...exerciseDateArray);
  exerciseDateArrayMax = Math.max(...exerciseDateArray);

  exerciseDurationArrayMin = Math.min(...exerciseDurationArray);
  exerciseDurationArrayMax = Math.max(...exerciseDurationArray);

  if (exerciseDateArray.length !== 0 && target2 !== null) {
    // console.log("week array= ", bmiDateArray);
    //console.log("bmi value array= ", bmiValueArray);

    var xexerciseArray = exerciseDateArray;
    var yexercisedurationArray = exerciseDurationArray;

    // Define Data
    var data4 = [
      {
        x: xexerciseArray,
        y: yexercisedurationArray,
        type: "scatter",
        plot: "jupyter",
      },
    ];

    // Define Layout
    var layout4 = {
      xaxis: {
        range: [exerciseDateArrayMin - 1, exerciseDateArrayMax + 1],
        title: "Days",
      },
      yaxis: {
        range: [exerciseDurationArrayMin - 1, exerciseDurationArrayMax + 1],
        title: "Duration",
      },
      title: "Exercise Duration Graph",
      autoexpand: true,
    };

    var config4 = { responsive: true };

    // Display using Plotly
    Plotly.newPlot(target4, data4, layout4, config4);
  }

  //EXERCISE SLEEP
  exerciseDateArrayMin = Math.min(...exerciseDateArray);
  exerciseDateArrayMax = Math.max(...exerciseDateArray);

  exerciseSleepArrayMin = Math.min(...exerciseSleepArray);
  exerciseSleepArrayMax = Math.max(...exerciseSleepArray);

  if (exerciseDateArray.length !== 0 && target2 !== null) {
    // console.log("week array= ", bmiDateArray);
    //console.log("bmi value array= ", bmiValueArray);

    var xexerciseArray = exerciseDateArray;
    var yexerciseSleepArray = exerciseSleepArray;

    // Define Data
    var data5 = [
      {
        x: xexerciseArray,
        y: yexerciseSleepArray,
        type: "scatter",
      },
    ];

    // Define Layout
    var layout5 = {
      xaxis: {
        range: [exerciseDateArrayMin - 1, exerciseDateArrayMax + 1],
        title: "Days",
      },
      yaxis: {
        range: [exerciseSleepArrayMin - 1, exerciseSleepArrayMax + 1],
        title: "sleep",
      },
      title: "Exercise Sleep Graph",
      autoexpand: true,
    };

    var config5 = { responsive: true };

    // Display using Plotly
    Plotly.newPlot(target5, data5, layout5, config5);
  }

  confidenceDateArrayMin = Math.min(...confidenceDateArray);
  confidenceDateArrayMax = Math.max(...confidenceDateArray);

  confidenceLevelArrayMin = Math.min(...confidenceLevelArray);
  confidenceLevelArrayMax = Math.max(...confidenceLevelArray);

  if (confidenceDateArray.length !== 0 && target2 !== null) {
    // console.log("week array= ", bmiDateArray);
    //console.log("bmi value array= ", bmiValueArray);

    var xconfidenceArray = confidenceDateArray;
    var yconfidenceLevelArray = confidenceLevelArray;

    // Define Data
    var data6 = [
      {
        x: xconfidenceArray,
        y: yconfidenceLevelArray,
        type: "scatter",
      },
    ];

    // Define Layout
    var layout6 = {
      xaxis: {
        range: [confidenceDateArrayMin - 1, confidenceDateArrayMax + 1],
        title: "Days",
      },
      yaxis: {
        range: [confidenceLevelArrayMin - 1, confidenceLevelArrayMax + 1],
        title: "confidence",
      },
      title: "Confidence Graph",
      autoexpand: true,
    };

    var config6 = { responsive: true };

    // Display using Plotly
    Plotly.newPlot(target6, data6, layout6, config6);
  }

  return (
    <div className="trackersContainer">
      <div className="tracker">
        <div className="trackerHeader"></div>
        <div className="trackerBody" id="trackerBody"></div>
      </div>
      <div className="tracker">
        <div className="trackerHeader"></div>
        <div className="trackerBody" id="trackerBody2"></div>
      </div>
      <div className="tracker">
        <div className="trackerHeader"></div>
        <div className="trackerBody" id="trackerBody3"></div>
      </div>
      <div className="tracker">
        <div className="trackerHeader"></div>
        <div className="trackerBody" id="trackerBody4"></div>
      </div>
      <div className="tracker">
        <div className="trackerHeader"></div>
        <div className="trackerBody" id="trackerBody5"></div>
      </div>
      <div className="tracker">
        <div className="trackerHeader"></div>
        <div className="trackerBody" id="trackerBody6"></div>
      </div>
    </div>
  );
}
export default GraphsCompo;
