import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { LoginContext } from "./contexts/LoginContext";
import LoginVerify from "./loginverify/LoginVerify";
import Login from "./pages/login";
import Admin from "./pages/admin";

function App() {
  const [isLogin, setIsLogin] = useState(false);

  return (
    <div>
      <LoginContext.Provider value={{ isLogin, setIsLogin }}>
        <Routes>
          <Route path="/"></Route>
          <Route
            exact
            path="/p"
            element={
              <Navigate push to={"/privacyPolicy_SahayaDiab.html"}></Navigate>
            }
          ></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route
            path="/admin"
            element={
              <LoginVerify isLoggedIn={isLogin}>
                <Admin />
              </LoginVerify>
            }
          ></Route>
        </Routes>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
