import axios from "axios";
import { useState } from "react";

import "../styles/patientDetailsStyle.css";

function PatientDetailsCompo(props) {
  const [editFlag, setEditFlag] = useState(false);

  function editPatientDetails() {
    setEditFlag(true);
    document.getElementById("patFirstName").disabled = false;
    document.getElementById("patLastName").disabled = false;
    document.getElementById("patPhoneNumber").disabled = false;
    document.getElementById("patEmail").disabled = false;
    document.getElementById("patAge").disabled = false;
    document.getElementById("patHeight").disabled = false;
    document.getElementById("editSaveBtn").style.display = "block";
    document.getElementById("editCancelBtn").style.display = "block";
    document.getElementById("editBtn").style.display = "none";
  }

  function cancelEdit() {
    setEditFlag(false);
    document.getElementById("patFirstName").disabled = true;
    document.getElementById("patLastName").disabled = true;
    document.getElementById("patPhoneNumber").disabled = true;
    document.getElementById("patEmail").disabled = true;
    document.getElementById("patAge").disabled = true;
    document.getElementById("patHeight").disabled = true;

    document.getElementById("editSaveBtn").style.display = "none";
    document.getElementById("editCancelBtn").style.display = "none";
    document.getElementById("editBtn").style.display = "block";
  }

  function openDeleteConfirmModal() {
    document.getElementsByClassName(
      "deleteConfirmationModal"
    )[0].style.display = "flex";
  }

  function closeDeleteConfirmModal() {
    document.getElementsByClassName(
      "deleteConfirmationModal"
    )[0].style.display = "none";
  }

  return (
    <div className="patientDetailsContainer">
      <div className="editFormControls">
        <button onClick={editPatientDetails} className="editBtn" id="editBtn">
          Edit
        </button>
        <button
          onClick={props.editpatientdata}
          className="editBtn"
          id="editSaveBtn"
        >
          Save
        </button>
        <button className="editBtn" id="editCancelBtn" onClick={cancelEdit}>
          Cancel
        </button>
      </div>
      <div className="formFields">
        <label htmlFor="hospitalId">Hospital ID</label>
        <input
          className="editInputField disabled"
          id="hospitalId"
          type="text"
          disabled
          onChange={props.listenhospitalid}
          placeholder={props.detailshospitalid}
        ></input>
      </div>
      <div className="formFields">
        <label htmlFor="patFirstName">First Name</label>
        <input
          className={
            editFlag === true
              ? "editInputField active"
              : "editInputField disabled"
          }
          id="patFirstName"
          type="text"
          disabled
          onChange={props.listennewfirstname}
          placeholder={props.detailsfirstname}
          value={props.newfirstname}
          // add values to all feilds
        ></input>
      </div>
      <div className="formFields">
        <label htmlFor="patLastName">Last Name</label>
        <input
          className={
            editFlag === true
              ? "editInputField active"
              : "editInputField disabled"
          }
          id="patLastName"
          type="text"
          disabled
          onChange={props.listennewlastname}
          placeholder={props.detailslastname}
          value={props.newlastname}
        ></input>
      </div>
      <div className="formFields">
        <label htmlFor="patPhoneNumber">Phone Number</label>
        <input
          className={
            editFlag === true
              ? "editInputField active"
              : "editInputField disabled"
          }
          id="patPhoneNumber"
          type="text"
          disabled
          onChange={props.listennewphonenumber}
          placeholder={props.detailsphonenumber}
          value={props.newphonenumber}
        ></input>
      </div>
      <div className="formFields">
        <label htmlFor="patEmail">Email ID</label>
        <input
          className={
            editFlag === true
              ? "editInputField active"
              : "editInputField disabled"
          }
          id="patEmail"
          type="text"
          disabled
          onChange={props.listennewemailid}
          placeholder={props.detailsemailid}
          value={props.newemailid}
        ></input>
      </div>
      <div className="formFields">
        <label htmlFor="patAge">Age</label>
        <input
          className={
            editFlag === true
              ? "editInputField active"
              : "editInputField disabled"
          }
          id="patAge"
          type="text"
          disabled
          onChange={props.listennewage}
          placeholder={props.detailsage}
          value={props.newage}
        ></input>
      </div>
      <div className="formFields">
        <label htmlFor="patHeight">Height</label>
        <input
          className={
            editFlag === true
              ? "editInputField active"
              : "editInputField disabled"
          }
          id="patHeight"
          type="text"
          disabled
          onChange={props.listennewheight}
          placeholder={props.detailsheight}
          value={props.newheight}
        ></input>
      </div>

      <div className="editDeleteControls">
        <button
          className="redClass"
          id="deleteBtn"
          onClick={openDeleteConfirmModal}
        >
          Delete Patient
        </button>
      </div>
      <div className="deleteConfirmationModal">
        <div className="confirmDialogue">
          <span>Confirm deletion of the patient {props.selectedPatient}</span>
          <div className="confirmControls">
            <button className="redClass" onClick={props.deletePatient}>
              Delete
            </button>
            <button className="blueClass" onClick={closeDeleteConfirmModal}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default PatientDetailsCompo;
