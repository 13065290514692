import "../styles/loginStyle.css";
import logoMain from "../assets/Logo_Main_SahayaDiab.svg";
import axios from "axios";
import { useState } from "react";
import { useContext } from "react";
import { LoginContext } from "../contexts/LoginContext";
import { useNavigate } from "react-router-dom";

function Login() {
  const [adminUserName, setAdminUserName] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { isLogin, setIsLogin } = useContext(LoginContext);
  console.log("insdie wizard>>>>>>>, ", isLogin);

  let navigate = useNavigate();

  function listenAdminUserName(event) {
    setAdminUserName(event.target.value);
  }
  function listenAdminPassword(event) {
    setAdminPassword(event.target.value);
  }

  async function handleLogin(event) {
    event.preventDefault();

    var adminUserNameLength =
      document.getElementById("adminUserName").value.length;
    var adminPasswordLength =
      document.getElementById("adminPassword").value.length;

    if (adminUserNameLength === 0 || adminPasswordLength === 0) {
      setErrorMessage("Fields can not be blank");
      return;
    }

    const config = {
      method: "POST",
      url: "https://asia-south1-sahayadiab.cloudfunctions.net/app/login",
      data: { adminUserName, adminPassword },
    };

    axios(config).then((result) => {
      if (result.data.userName === null) {
        setIsLogin(false);
        setErrorMessage("Username ans/ or Password does not match");
      } else {
        setIsLogin(true);
        sessionStorage.setItem("adminUserName", adminUserName);
        navigate("/admin");
      }
    });
  }

  return (
    <div className="loginPageParentContainer">
      <div className="loginLogoHeaderModule">
        <div className="loginLogoImageContainer">
          <img className="loginLogoImage" src={logoMain} alt="Logo"></img>
        </div>
        <div className="projectTitle">
          <h4>SahayaDiab</h4>
        </div>
      </div>
      <div className="loginModule">
        <p>{errorMessage}</p>
        <div className="loginFields">
          <input
            id="adminUserName"
            type="text"
            placeholder="User Name"
            onChange={listenAdminUserName}
            value={adminUserName}
          ></input>
          <input
            id="adminPassword"
            type="text"
            placeholder="Password"
            onChange={listenAdminPassword}
            value={adminPassword}
          ></input>
        </div>
        <div className="loginControls">
          <button className="loginBtn" onClick={handleLogin}>
            Login
          </button>
        </div>
      </div>
    </div>
  );
}
export default Login;
