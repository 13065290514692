import "../styles/patientDashboardStyle.css";

import { useEffect, useMemo, useState } from "react";

import GraphsCompo from "./graphsCompo";
import PatientDetailsCompo from "./patientDetailsCompo";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

function PatientDashboardCompo(props) {
  const [flagGraph, setFlagGraph] = useState(true);
  const [flagPatientDetails, setflagPatientDetails] = useState(false);
  // const [hospitalId, setHospitalId] = useState();

  // console.log("bmi date inside dashboard", props.bmiDateArray);
  /*
  useEffect(() => {
    axios.get("/getgraphdata").then(function (res) {
      //console.log("get bmi array==== ", res.data[0].PatientBmi);
      // setPatientList(res.data);
    });
  }, []);
  */

  function openGraphs() {
    setflagPatientDetails(false);
    setFlagGraph(true);
  }

  function openPatientDetails() {
    setFlagGraph(false);
    setflagPatientDetails(true);
    // use axios.get to display patient details and console log
    // and figure out to access selected values
  }
  // const[abc,setabc] = useState()
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";
  const fileExtension = ".xlsx";
  const fileName1 = props.selectedPatient + " Patient details";
  const fileName2 = props.selectedPatient + " Bmi details";
  const fileName3 = props.selectedPatient + " Glucouse details";
  const fileName4 = props.selectedPatient + " Exercise data";
  const fileName5 = props.selectedPatient + " Confidence data";

  const exportData1 = async () => {
    var patdataarray = [];
    patdataarray.push(props.patdata);

    //console.log("the fuck... ", patdataarray);

    var wb1 = XLSX.utils.book_new();
    wb1.Props = { Subject: "test subject", Author: "bharat" };
    var ws1 = XLSX.utils.aoa_to_sheet([["Patient Details"]]);
    XLSX.utils.sheet_add_json(ws1, patdataarray, { origin: -1 });

    XLSX.utils.sheet_add_aoa(ws1, [[""]], { origin: -1 });
    XLSX.utils.sheet_add_aoa(ws1, [["Patient BMI Data"]], { origin: -1 });
    XLSX.utils.sheet_add_json(ws1, props.bmidata, { origin: -1 });

    XLSX.utils.sheet_add_aoa(ws1, [[""]], { origin: -1 });
    XLSX.utils.sheet_add_aoa(ws1, [["Patient Glucose Data"]], { origin: -1 });
    XLSX.utils.sheet_add_json(ws1, props.glucousedata, { origin: -1 });

    XLSX.utils.sheet_add_aoa(ws1, [[""]], { origin: -1 });
    XLSX.utils.sheet_add_aoa(ws1, [["Patient Exercise Data"]], { origin: -1 });
    XLSX.utils.sheet_add_json(ws1, props.exercisedata, { origin: -1 });

    XLSX.utils.sheet_add_aoa(ws1, [[""]], { origin: -1 });
    XLSX.utils.sheet_add_aoa(ws1, [["Patient Confidence"]], { origin: -1 });
    XLSX.utils.sheet_add_json(ws1, props.confidencedata, { origin: -1 });

    XLSX.utils.book_append_sheet(wb1, ws1, "bmi sheet this is");
    XLSX.writeFile(wb1, "testfile.xlsx");

    /*
    const ws1 = XLSX.utils.json_to_sheet(patdataarray);
    const wb1 = { Sheets: { data: ws1 }, SheetNames: ["data"] };
    const excelBuffer1 = XLSX.write(wb1, { bookType: "xlsx", type: "array" });
    const data1 = new Blob([excelBuffer1], { type: fileType });
    FileSaver.saveAs(data1, fileName1 + fileExtension);
    */

    /*
    const ws2 = XLSX.utils.json_to_sheet(props.bmidata);
    const wb2 = { Sheets: { data: ws2 }, SheetNames: ["data"] };
    const excelBuffer2 = XLSX.write(wb2, { bookType: "xlsx", type: "array" });
    const data2 = new Blob([excelBuffer2], { type: fileType });
    FileSaver.saveAs(data2, fileName2 + fileExtension);

    const ws3 = XLSX.utils.json_to_sheet(props.glucousedata);
    const wb3 = { Sheets: { data: ws3 }, SheetNames: ["data"] };
    const excelBuffer3 = XLSX.write(wb3, { bookType: "xlsx", type: "array" });
    const data3 = new Blob([excelBuffer3], { type: fileType });
    FileSaver.saveAs(data3, fileName3 + fileExtension);

    const ws4 = XLSX.utils.json_to_sheet(props.exercisedata);
    const wb4 = { Sheets: { data: ws4 }, SheetNames: ["data"] };
    const excelBuffer4 = XLSX.write(wb4, { bookType: "xlsx", type: "array" });
    const data4 = new Blob([excelBuffer4], { type: fileType });
    FileSaver.saveAs(data4, fileName4 + fileExtension);

    const ws5 = XLSX.utils.json_to_sheet(props.confidencedata);
    const wb5 = { Sheets: { data: ws5 }, SheetNames: ["data"] };
    const excelBuffer5 = XLSX.write(wb5, { bookType: "xlsx", type: "array" });
    const data5 = new Blob([excelBuffer5], { type: fileType });
    FileSaver.saveAs(data5, fileName5 + fileExtension);
    */
  };

  //   const exportData2 = async () => {
  //     // const fileName2 = selectedPatient+' Bmi details';
  //     // console.log(typeof(fileName1), fileName1);

  //     // setAbc(async () => {
  //     // const xyz = () => {
  //    console.log("exportBmiDataaaa : ",props.bmidata)
  //    console.log(typeof(props.bmidata))
  //     const ws1 = XLSX.utils.json_to_sheet(props.bmidata);
  //     const wb1 = {Sheets: { 'data': ws1 }, SheetNames: ['data'] };
  //     const excelBuffer1 = XLSX.write(wb1, { bookType: 'xlsx', type: 'array' });
  //     const data1 = new Blob ([excelBuffer1], { type: fileType });
  //     FileSaver.saveAs (data1, fileName1 + fileExtension);
  // }

  return (
    <div className="patientDashboardParentContainer">
      <div className="patientDashboardContainer">
        <div className="selectedPatient">
          <span className="componentHeading">
            Selected Patient - {props.selectedPatient}
          </span>
          <button id="exportBtn" onClick={exportData1}>
            Export
          </button>
        </div>

        <div className="patientTabsContainer">
          <button
            className={flagGraph === true ? "tabButton selected" : "tabButton"}
            id="graphButton"
            onClick={openGraphs}
          >
            Graphs
          </button>
          <button
            className={
              flagPatientDetails === true ? "tabButton selected" : "tabButton"
            }
            id="detailsButton"
            onClick={openPatientDetails}
          >
            Details
          </button>
        </div>
        <div className="trackersAndDetailsContainer">
          {flagPatientDetails === true ? (
            <PatientDetailsCompo
              selectedPatient={props.selectedPatient}
              deletePatient={props.deletePatient}
              listennewfirstname={props.listennewfirstname}
              listennewlastname={props.listennewlastname}
              listennewphonenumber={props.listennewphonenumber}
              listennewemailid={props.listennewemailid}
              listennewage={props.listennewage}
              listennewheight={props.listennewheight}
              editpatientdata={props.editpatientdata}
              detailshospitalid={props.detailshospitalId}
              detailsfirstname={props.detailsfirstname}
              detailslastname={props.detailslastname}
              detailsphonenumber={props.detailsphonenumber}
              detailsemailid={props.detailsemailid}
              detailsage={props.detailsage}
              detailsheight={props.detailsheight}
              newfirstname={props.newfirstname}
              newlastname={props.newlastname}
              newphonenumber={props.newphonenumber}
              newemailid={props.newemailid}
              newage={props.newage}
              newheight={props.newheight}
            />
          ) : (
            <GraphsCompo
              bmiDateArray={props.bmiDateArray}
              bmiValueArray={props.bmiValueArray}
              glucoseDateArray={props.glucoseDateArray}
              glucoseValueArray={props.glucoseValueArray}
              exerciseDateArray={props.exerciseDateArray}
              exerciseIntensityArray={props.exerciseIntensityArray}
              exerciseDurationArray={props.exerciseDurationArray}
              exerciseSleepArray={props.exerciseSleepArray}
              confidenceDateArray={props.confidenceDateArray}
              confidenceLevelArray={props.confidenceLevelArray}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default PatientDashboardCompo;
