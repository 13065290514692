import "../styles/headerStyle.css";
import logoSmall from "../assets/Logo_Small_SahayaDiab.svg";

function Header() {
  return (
    <div className="headerParentContainer">
      <div className="logoHeadingModule">
        <div className="logoImageContainer">
          <img className="logoImage" src={logoSmall} alt="Logo"></img>
        </div>
        <div className="headingContainer">
          <span>SahayaDiab</span>
        </div>
      </div>
    </div>
  );
}
export default Header;
